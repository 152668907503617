import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiUltimaLeituraCondensador = {
    fetchApi: async (condensadorId) => {
        const URL = `${apiLink}api/leitura-condensador/ultima-leitura?condensadorId=${condensadorId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar a última leitura do condensador');

            })
    },
    getVazoesVolumetricasAr: async (unidadeId) => {
        const URL = `${apiLink}api/leitura-condensador/vazoes-volumetrica-ar-dia/${unidadeId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                throw new Error(error.message);
            })
    }
}

export default apiUltimaLeituraCondensador;
