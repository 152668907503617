import { useContext, useEffect, useState } from "react";
import getCurrentUnidadeId from "../../funcs/getCurrentUnidadeId";
import apiRelatorio from "../../services/apis/apiRelatorio";
import CardLayout from "../../components/common/CardLayout";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../components/ui/accordion";
import NoDataAlert from "../../components/common/returns/NoDataAlert";
import { DatePicker } from "../../components/ui/datepicker";
import { Button } from "../../components/ui/button";
import { getClientTimeZoneOffset } from "../../funcs";
import DateIntervalPopOver from "../../components/relatorio/DateIntervalPopOver";
import { FaFileExcel, FaSpinner } from "react-icons/fa";
import { ToastContext } from "../../contexts/ToastContext";
import apiUnidade from "../../services/apis/apiUnidade";
import Condensacao from "../../components/relatorio/Condensacao";
import ChartViewLineVazoes from "../../components/relatorio/condensadores/ChartLineVazoesVolAr";
import ChartViewLineCorrente from "../../components/relatorio/condensadores/ChartLineCorrente";

export default function Condensador() {
    const UNIDADE = getCurrentUnidadeId();
    const [dados, setDados] = useState([]);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [tempSelectedDate, setTempSelectedDate] = useState(new Date());
    const [isLoadingRelatorio, setIsLoadingRelatorio] = useState(false);
    const [dadosCondensacao, setDadosCondensacao] = useState([]);
    const [unidade, setUnidade] = useState();
    const { erro } = useContext(ToastContext);

    useEffect(() => {
        const fetchData = async () => {
            const res = await apiUnidade.getById(UNIDADE);
            setUnidade(res.data);
        }
        fetchData();
    }, [UNIDADE])

    const handleDateChange = (date) => {
        setTempSelectedDate(date);
    };

    const handleSearch = async () => {
        setSelectedDate(tempSelectedDate);
    };

    useEffect(() => {
        const fetchData = async () => {
            const dataFormatada = selectedDate?.toLocaleDateString('en-CA', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit'
            });

            const timeZoneOffset = getClientTimeZoneOffset();

            const fusoData = {
                data: dataFormatada,
                TimeZoneNumber: timeZoneOffset
            }

            const res = await apiRelatorio.relatorioCondensador(UNIDADE, fusoData)
            const resCondensacao = await apiRelatorio.relatorioCondensacao(UNIDADE, fusoData);
            setDadosCondensacao(resCondensacao.data);
            setDados(res.data);
        }
        fetchData();
    }, [selectedDate, UNIDADE]);

    const gerarRelatorio = async (tempSelectedDateDe, tempSelectedDateAte) => {
        try {
            setIsLoadingRelatorio(true);
            const timeZoneOffset = getClientTimeZoneOffset();

            const dataInfo = {
                dataInicio: tempSelectedDateDe,
                dataFim: tempSelectedDateAte,
                TimeZoneNumber: timeZoneOffset
            }

            const dataInicioFormatada = dataInfo.dataInicio.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            const dataFinalFormatada = dataInfo.dataFim.toLocaleString('pt-BR', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: false
            }).replaceAll(",", "_").replaceAll(" ", "");

            //se data inicio for maior que data final
            if (dataInfo.dataInicio > dataInfo.dataFim) {
                erro('Data de início precisa ser maior que data final');
                return;
            }

            const response = await apiRelatorio.relatorioLeituraCompressor(unidade.id, dataInfo);
            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', `Leituras_Compressores_${unidade.nome}_${dataInicioFormatada}-${dataFinalFormatada}.xlsx`);

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

        } catch (error) {
            erro(error.message);
        } finally {
            setIsLoadingRelatorio(false);
        }
    }

    return (
        <section className="flex flex-col pb-16">
            <div className="flex items-center justify-center">
                <div className="flex justify-center items-center w-full p-4 gap-2">
                    <DatePicker
                        selectedDate={tempSelectedDate}
                        onDateChange={handleDateChange}
                    />
                    <Button onClick={handleSearch}>
                        Buscar
                    </Button>
                    {/* <DateIntervalPopOver onGerarRelatorio={gerarRelatorio} disabled={isLoadingRelatorio}>
                        {
                            isLoadingRelatorio ?
                                <div className="animate-spin">
                                    <FaSpinner size={18} />
                                </div>
                                :
                                <FaFileExcel size={18} />
                        }

                    </DateIntervalPopOver> */}
                </div>
                <div className="inline-flex w-32 justify-end px-2">
                    <p className="text-sm text-secondary drop-shadow-1xl">
                        {selectedDate
                            ? selectedDate.toLocaleDateString(
                                'pt-BR',
                                {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                }
                            )
                            : ''}
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-4 py-2">
                <Condensacao dadosCondensacao={dadosCondensacao}/>
                {

                    dados.map((condensador) => (
                        <CardLayout key={condensador.id}>
                            <Accordion
                                type="single"
                                defaultValue={`item-${condensador.id}`}
                                collapsible={true}
                            >
                                <AccordionItem
                                    value={`item-${condensador.id}`}
                                >
                                    <div className="font-semibold">
                                        <AccordionTrigger>
                                            <div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl">
                                                {condensador &&
                                                    <div
                                                        className={`uppercase`}
                                                    >
                                                        <p>
                                                            <span className="capitalize">
                                                                {
                                                                    condensador.nome
                                                                }
                                                            </span>
                                                        </p>
                                                    </div>
                                                }
                                            </div>
                                        </AccordionTrigger>
                                    </div>
                                    {
                                        condensador.leiturasCondensadores.length === 0 ?
                                            <div className="flex flex-col gap-4 justify-center w-full items-center" key={condensador.id}>
                                                <div className="w-[300px]">
                                                    <NoDataAlert />
                                                </div>
                                            </div>
                                            :
                                            <AccordionContent>
                                                <div className="flex flex-col justify-center items-center w-full px-2" key={condensador.id}>
                                                    <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                            Capacidade medida:
                                                        </p>
                                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                            {condensador.leiturasCondensadores.at(-1).capacidadeMedida.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ) + " Kcal/h"}
                                                        </span>
                                                    </div>
                                                    <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                            I.E medido:
                                                        </p>
                                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                            {condensador.leiturasCondensadores.at(-1).ieeReal.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ) + " Kcal/h"}
                                                        </span>
                                                    </div>
                                                    <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                            I.E nominal:
                                                        </p>
                                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                            {condensador.leiturasCondensadores.at(-1).ieeNominal.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ) + " Kcal/h"}
                                                        </span>
                                                    </div>
                                                    <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                            TBU admissão do ar:
                                                        </p>
                                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                            {condensador.leiturasCondensadores.at(-1).tbuAdmissao.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ) + " °C"}
                                                        </span>
                                                    </div>
                                                    <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                            Ganho energético do ar:
                                                        </p>
                                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                            {condensador.leiturasCondensadores.at(-1).ganhoEnergetico.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ) + " kj/kg"}
                                                        </span>
                                                    </div>
                                                    <div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[30rem] 3xl:px-4 my-2">
                                                        <p className="text-sm 3xl:text-lg font-bold px-4 text-accent">
                                                            Umidade relativa:
                                                        </p>
                                                        <span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
                                                            {condensador.leiturasCondensadores.at(-1).umidadeRelativaSaida.toLocaleString(
                                                                'pt-BR',
                                                                {
                                                                    maximumFractionDigits: 2,
                                                                }
                                                            ) + " %"}
                                                        </span>
                                                    </div>
                                                    <div className="flex flex-col items-center w-full h-full lg:w-7/12 2xl:3/4">
                                                        <h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl mt-1">
                                                            Vazao volumetrica do Ar
                                                        </h2>
                                                        <ChartViewLineVazoes condensador={condensador} />
                                                    </div>
                                                </div>
                                                <div className="flex flex-col lg:flex-row justify-center items-center w-full px-5" >
                                                    <div className="flex flex-col items-center w-full h-full lg:w-7/12 2xl:3/4">
                                                        <h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl mt-1">
                                                            Corrente bombas
                                                        </h2>
                                                        <ChartViewLineCorrente equipamentos={condensador.bombas} />
                                                    </div>
                                                    <div className="flex flex-col items-center w-full h-full lg:w-7/12 2xl:3/4">
                                                        <h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl mt-1">
                                                            Corrente ventiladores
                                                        </h2>
                                                        <ChartViewLineCorrente equipamentos={condensador.ventiladores} chartType={"ventilador"} />
                                                    </div>

                                                </div>

                                            </AccordionContent>
                                    }
                                </AccordionItem>
                            </Accordion>
                        </CardLayout>
                    ))
                }
            </div>
        </section>
    );
}