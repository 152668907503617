import React, { useEffect, useState } from 'react';
import SmallCardLayout from '../../common/SmallCardLayout';
import { colorsPallete } from '../../../constants';
import apiChiller from '../../../services/apis/apiChiller';
import apiUltimaLeituraChiller from '../../../services/apis/Leituras/apiUltimaLeituraChiller';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';
import compareObjects from '../../../funcs/compareObjects';
import { FaSignInAlt } from 'react-icons/fa';

const ListChillers = ({ listaRegimes }) => {
	const [listaChillers, setListaChillers] = useState([]);
	const [listaTemperaturas, setListaTemperaturas] = useState([]);
	const [hubConnection, setHubConnection] = useState();
	const [dadoAtualizado, setDadoAtualizado] = useState();

	//GET CHILLERS
	useEffect(() => {
		let colorIndex = 0;
		setListaChillers([]);
		listaRegimes.length > 0 &&
			listaRegimes.forEach((elemento) => {
				if (elemento.tipoRegime !== 'Condensação') {
					elemento['RegimeColor'] =
						colorsPallete[colorIndex].borderClass;
					colorIndex += 1;

					apiChiller
						.getByRegimeId(elemento.id)
						.then((res) => {
							let chillers = res.data;
							if (chillers.length > 0) {
								chillers.forEach((chiller) => {
									chiller['borderClass'] =
										elemento[
											'RegimeColor'
										];
								});
								setListaChillers((current) => [
									...current,
									chillers,
								]);
							}
						})
						.catch((error) => {
							console.log(
								`Error fetching chillers - [ ListChillers ]:`,
								error
							);
						});
				}
			});
	}, [listaRegimes]);

	useEffect(() => {
		setListaTemperaturas([]);
		listaChillers.forEach((listaTemperatura) => {
			listaTemperatura.forEach((elemento) => {
				apiUltimaLeituraChiller
					.fetchApi(elemento.id)
					.then((res) => {
						if (res === undefined) {
							setListaTemperaturas((current) => [
								...current,
								{
									temperaturaEntrada: '-',
									chillerId: elemento.id,
								},
							]);
						} else {
							setListaTemperaturas((current) => [
								...current,
								res.data,
							]);
						}
					})
					.catch((error) => {
						console.error(
							`Error fetching ultima leitura Chiller [ListChillers]:, ${error}`,
							error
						);
					});
			});
		});
	}, [listaChillers]);

	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(`${apiLink}LeituraChillerHub`)
		);
	}, []);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then((result) => {
					hubConnection.on(
						'AtualizarDadosChiller',
						(message) => {
							setDadoAtualizado(message);
						}
					);
				})
				.catch((error) => {
					console.log(
						'Conexão falhou. Erro:',
						error.message
					);
				});
		}
	}, [hubConnection]);

	// ATUALIZAÇÃO EM TEMPO REAL

	useEffect(() => {
		if (dadoAtualizado) {
			try {
				const index = listaTemperaturas.findIndex(
					(elemento) => {
						return (
							elemento.chillerId ===
							dadoAtualizado.chillerId
						);
					}
				);
				if (
					!compareObjects(
						dadoAtualizado,
						listaTemperaturas[index]
					)
				) {
					let arrayAux = [...listaTemperaturas];
					arrayAux[index] = dadoAtualizado;
					setListaTemperaturas(arrayAux);
				}
			} catch (error) {
				console.log(
					`Error comparing objects [ListAmbientes] [ListChillers]: `,
					error
				);
			}
		}
	}, [dadoAtualizado, listaTemperaturas]);

	function getTemperaturaByChillerId(id, out = false) {
		const leitura = listaTemperaturas.find(
			(elemento) => elemento.chillerId === id
		);
		return out
			? leitura?.temperaturaSaida
			: leitura?.temperaturaEntrada;
	}

	function getReference(id) {
		const ref = listaChillers.flat().find((obj) => obj.id === id);
		return ref?.temperaturaReferenciaAgua;
	}

	function formatTemperatura(id, out = false) {
		const temp = getTemperaturaByChillerId(id, out);
		const ref = getReference(id);

		let value = temp.toLocaleString('pt-BR', {
			maximumFractionDigits: 2,
		});

		let classTemp = '';

		if (temp > ref) {
			classTemp = 'text-red-500 animate-pulse';
		} else if (temp < ref - 5) {
			classTemp = 'text-yellow-300 animate-pulse';
		} else {
			classTemp = 'text-white';
		}

		return { value, classTemp };
	}

	const allChillers = listaChillers.flatMap((regime, index) => {
		return regime.map((ambiente) => ({
			...ambiente,
		}));
	});


	return (
		<>
			{allChillers.length > 0 ? (
				<div className="flex flex-col w-full mt-3">
					<div className="flex h-full text-white w-full mb-3">
						<div className="flex flex-wrap  text-center h-full w-full mb-3">
							<SmallCardLayout className="flex items-center border-glassColor h-full justify-center gap-x-3 w-full">
								<>
									<p>C</p>
									<p>H</p>
									<p>I</p>
									<p>L</p>
									<p>L</p>
									<p>E</p>
									<p>R</p>
									<p>S</p>
								</>
							</SmallCardLayout>
						</div>
					</div>
					<div className="flex flex-wrap w-full h-full gap-y-2 gap-x-4">
						{allChillers.map((chiller, i) => (
							<div
								className={
									allChillers.length <= 3
										? 'w-full'
										: 'w-full min-w-[22rem] flex-1'
								}
								key={i}
							>
								<SmallCardLayout
									className={`${chiller.borderClass} h-full`}
								>
									<div className="flex flex-col flex-grow items-center w-full h-full justify-center">
										<div className="flex items-center h-full text-secondary drop-shadow-1xl text-lg text-center">
											{chiller.nome}{' '}
										</div>
										<div className="flex items-center h-full">
											<FaSignInAlt className="text-accent/40 w-6 h-6 mr-3" />
											{!isNaN(getTemperaturaByChillerId(
												chiller.id
											)) ? (
												<span
													className={`font-bold drop-shadow-1xl text-lg 2xl:text-3xl ${
														formatTemperatura(
															chiller.id
														)
															.classTemp
													}`}
												>
													{
														formatTemperatura(
															chiller.id
														)
															.value
													}
													<p className="text-secondary inline-flex px-3 text-sm lg:text-lg">
														°C
													</p>
												</span>
											) : (
												<span className="animate-pulse text-alarm/60 font-semibold drop-shadow-1xl text-lg">
													OFF
												</span>
											)}
											{/* <div className="flex items-center h-full text-accent px-4">
												|
											</div>
											<FaSignOutAlt className="text-accent/40 w-6 h-6 mr-2" /> */}
											{/* {!isNaN(getTemperaturaByChillerId(
												chiller.id,
												true
											)) ? (
												<span
													className={`font-bold drop-shadow-1xl text-lg 2xl:text-3xl ${
														formatTemperatura(
															chiller.id
														)
															.classTemp
													}`}
												>
													{
														formatTemperatura(
															chiller.id,
															true
														)
															.value
													}
													<p className="text-secondary inline-flex px-3 text-sm lg:text-lg">
														°C
													</p>
												</span>
											) : (
												<span className="animate-pulse text-alarm/60 font-semibold drop-shadow-1xl text-lg">
													OFF
												</span>
											)} */}
										</div>
									</div>
								</SmallCardLayout>
							</div>
						))}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default ListChillers;
